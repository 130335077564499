import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Content, { HTMLContent } from "../components/content";
import Slider from "react-slick";
import BackgroundImage from "gatsby-background-image";
import CallToAction from "../components/calltoaction";
import Img from "gatsby-image";

const RealisationsPageTemplate = ({
  title,
  backgroundImageFile,
  content,
  contentComponent,
  realisations
}) => {
  const PageContent = contentComponent || Content;

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <BackgroundImage
        Tag="div"
        className="mb-10 bg-opacity-50 h-32 sm:h-64 bg-cover bg-no-repeat bg-center flex items-center content-center justify-center"
        fluid={backgroundImageFile.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <h1 className="text-white text-center text-3xl sm:text-6xl">{title}</h1>
      </BackgroundImage>
      <section className="max-w-screen-xl mx-auto p-4 sm:p-6">
        <PageContent content={content} />

        <div className="mt-10">
          {realisations.map((item) => (
            <div
              className="max-w-sm rounded overflow-hidden shadow-lg"
              key={item.title}
            >
              <Slider {...sliderSettings}>
                <Img
                  fluid={item.image_before.childImageSharp.fluid}
                  alt={item.title}
                />
                <Img
                  fluid={item.image_after.childImageSharp.fluid}
                  alt={item.title}
                />
              </Slider>
              <div className="px-6 pt-8 pb-4">
                <div className="font-bold text-xl">{item.title}</div>
              </div>
              <div className="px-6 pb-8">
                <div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                  <div className="flex">
                    <svg
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 mr-2"
                    >
                      <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                      <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    <span>{item.city}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <CallToAction />
    </div>
  );
};

RealisationsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  background_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  realisations: PropTypes.array,
};

const RealisationsPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <Layout>
      <SEO
        title={
          page.frontmatter.meta_title
            ? page.frontmatter.meta_title
            : page.frontmatter.title
        }
        description={
          page.frontmatter.meta_description
            ? page.frontmatter.meta_description
            : ""
        }
      />
      <RealisationsPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        backgroundImageFile={page.frontmatter.background_image}
        content={page.html}
        realisations={page.frontmatter.realisations_block.realisations}
      />
    </Layout>
  );
};

RealisationsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RealisationsPage;

export const pageQuery = graphql`
         query RealisationsPageTemplate($id: String!) {
           markdownRemark(id: { eq: $id }) {
             html
             frontmatter {
               title
               background_image {
                 childImageSharp {
                   fluid(maxWidth: 1024, quality: 90) {
                     ...GatsbyImageSharpFluid_withWebp
                   }
                 }
               }
               realisations_block {
                 realisations {
                   title
                   city
                   image_before {
                     childImageSharp {
                       fluid(maxWidth: 600, quality: 90) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                   image_after {
                     childImageSharp {
                       fluid(maxWidth: 600, quality: 90) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                 }
               }
               meta_title
               meta_description
             }
           }
         }
       `;
